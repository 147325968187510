<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="6"
        xl="4"
        v-for="address in currentAddresses"
        :key="address.addressId"
      >
        <AddressCard
          class="pa-0 pa-sm-3"
          outlined
          :address="address"
          :user="user"
          disabled
          :selectionEnabled="false"
          :type="address.addressTypeId"
          @setdelivery="setDeliveryAddress"
          @updateaddress="updateAddress"
        />
      </v-col>
    </v-row>

    <div class="mt-7 d-flex justify-center">
      <router-link
        class="bottom-page-link"
        :to="{
          name: 'Page',
          params: { pageName: 'delivery' }
        }"
      >
        {{ $t("addresses.bottomPageLink") }}
      </router-link>
    </div>
  </div>
</template>

<script>
import AddressCard from "@/components/delivery/AddressCard.vue";

import SogegrossDeliveryService from "@/service/grosmarketCustomService";

import { mapActions } from "vuex";

export default {
  props: ["addresses", "user"],
  data() {
    return {
      currentAddresses: []
    };
  },
  components: { AddressCard },
  methods: {
    ...mapActions({ setAddress: "cart/setAddress" }),
    /**
     * imposto il valore selected all'indirizzo prima di aggiornarlo
     */
    async setDeliveryAddress(address) {
      // let selectedAddress = address;
      // selectedAddress.selected = 1;
      // this.updateAddress(selectedAddress);
      let res = await this.setAddress({
        addressType: address.addressType,
        addressId: address.addressId
      });
      if (res && !res.errors) {
        this.$emit("refresh");
      } else {
        if (res.errors) {
          global.EventBus.$emit("error", {
            error: res.errors[0].error,
            message: res.errors[0].error
          });
        }
      }
    },
    /**
     * aggiorno l'indirizzo e poi effettuo reload di tutti gli indirizzi
     */
    async updateAddress(address) {
      try {
        this.error = null;
        this.loading = true;
        console.log(address);
        let res = await SogegrossDeliveryService.updateAddress(address);
        console.log(res);
        this.loading = false;
        if (res && !res.errors) {
          this.$emit("refresh");
        } else {
          if (res.errors) {
            global.EventBus.$emit("error", {
              error: res.errors[0].error,
              message: res.errors[0].error
            });
          }
        }
      } catch (response) {
        this.response = response;
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    addresses(val, oldVal) {
      if (val !== oldVal) {
        this.currentAddresses = val;
      }
    }
  }
};
</script>
