<template>
  <div class="address-card-container">
    <v-card
      flat
      class="address"
      v-bind:class="[address.addressId == shippingAddressId ? 'selected' : '']"
    >
      <!-- blocco azioni superiori -->
      <v-card-actions class="justify-space-between">
        <!-- indirizzo di spedizione -->
        <v-btn
          v-if="address.addressId == shippingAddressId"
          elevation="0"
          text
          x-small
          class="pa-0"
          disabled
        >
          <v-icon small class="mr-1">$starfull</v-icon>
          <span class="delivery-address-select">{{
            $t("addresses.card.deliveryAddressYes")
          }}</span>
        </v-btn>
        <v-btn
          v-else-if="address.addressTypeId === 2"
          elevation="0"
          text
          x-small
          class="pa-0"
          @click.stop="setDeliveryAddress"
        >
          <v-icon small class="mr-1">$star</v-icon>
          <span class="delivery-address-select">{{
            $t("addresses.card.deliveryAddressNo")
          }}</span>
        </v-btn>

        <v-spacer />

        <v-btn
          v-if="address.addressTypeId === 2"
          class="has-delivery"
          text
          x-small
        >
          <!-- Indirizzo coperto dal servizio e attivo pe rla delivery -->
          {{ $t("addresses.card.hasDeliveryYes") }}
        </v-btn>
        <v-btn
          v-else-if="address.addressTypeId === 102 && address.zoneId > 0"
          class="has-delivery disabled"
          text
          x-small
          @click="sendAddressActivationRequest"
        >
          <!-- Indirizzo coperto dal servizio e ma non attivo per la delivery -->
          {{ $t("addresses.card.hasDeliveryNo") }}
        </v-btn>
        <v-btn
          v-else
          class="has-delivery disabled text-grey"
          text
          x-small
          disabled
        >
          <!-- Indirizzo  non coperto dal servizio e ma non attivo per la delivery -->
          {{ $t("addresses.card.noDelivery") }}
        </v-btn>
      </v-card-actions>

      <!-- TODO: nome azienda (prendo dallo user oppure da dove?)-->
      <v-card-title
        class="px-4 text-left mb-3"
        :class="{ 'industry-hasnt-delivery': !industryHasDelivery }"
      >
        {{ address.addressName }}</v-card-title
      >
      <v-card-subtitle class="pb-0 px-4 text-left"
        >{{ $t("addresses.card.cardNumber") }}
        <!-- TODO: numero tessera (da dove?) -->
        <span class="value">{{ address.codInt }}</span>
      </v-card-subtitle>
      <!-- indirizzo -->
      <v-card-text class="pb-0 px-4 text-left">
        <div>
          {{ address.address1 }} {{ address.addressNumber }},
          {{ address.city }} {{ address.postalcode }} ({{ address.province }})
        </div>
      </v-card-text>

      <!-- campi di input -->
      <v-card-actions class="fields d-flex flex-column pa-0">
        <!-- telefono -->
        <v-row class="">
          <v-col cols="6" class="pa-0 pl-2 pr-1">
            <v-text-field
              ref="homephone"
              v-model="address.referencePhoneNumber"
              :error-messages="errors"
              type="tel"
              height="32"
              :placeholder="$t('addresses.card.phoneNumber')"
              :outlined="editingPhone"
              :rules="isHomePhoneRules"
              dense
              :clearable="editingPhone"
              :readonly="!editingPhone"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pa-0 pr-1 d-flex justify-center">
            <span
              v-if="!editingPhone"
              :class="address.referencePhoneNumber ? 'edit' : 'insert'"
              @click="editField('editingPhone')"
              >{{
                address.referencePhoneNumber
                  ? $t("addresses.card.editField")
                  : $t("addresses.card.insertField")
              }}</span
            >
            <div v-else class="d-flex flex-row">
              <v-btn
                class="ml-0 mr-1 white--text"
                color="primary"
                elevation="0"
                dense
                :disabled="!isValidInput('homephone')"
                @click="
                  confirmEdit(address.referencePhoneNumber, 'editingPhone')
                "
                >{{ $t("addresses.card.confirmField") }}</v-btn
              >
              <v-btn
                class="ml-0"
                elevation="0"
                dense
                @click="
                  resetField(
                    'homephone',
                    'referencePhoneNumber',
                    'editingPhone'
                  )
                "
                >{{ $t("addresses.card.resetField") }}</v-btn
              >
            </div>
          </v-col>
        </v-row>

        <!-- cellulare -->
        <v-row class="">
          <v-col cols="6" class="pa-0 pl-2 pr-1">
            <v-text-field
              ref="mobilephone"
              v-model="address.referenceMobileNumber"
              :error-messages="errors"
              type="tel"
              height="32"
              :rules="isMobilePhoneRules"
              :placeholder="$t('addresses.card.mobileNumber')"
              :outlined="editingMobile"
              dense
              :clearable="editingMobile"
              :readonly="!editingMobile"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pa-0 pr-1 d-flex justify-center">
            <span
              v-if="!editingMobile"
              :class="address.referenceMobileNumber ? 'edit' : 'insert'"
              @click="editField('editingMobile')"
              >{{
                address.referenceMobileNumber
                  ? $t("addresses.card.editField")
                  : $t("addresses.card.insertField")
              }}</span
            >
            <div v-else class="d-flex flex-row">
              <v-btn
                class="ml-0 mr-1 white--text"
                color="primary"
                elevation="0"
                dense
                :disabled="!isValidInput('mobilephone')"
                @click="
                  confirmEdit(address.referenceMobileNumber, 'editingMobile')
                "
                >{{ $t("addresses.card.confirmField") }}</v-btn
              >
              <v-btn
                class="ml-0"
                elevation="0"
                dense
                @click="
                  resetField(
                    'mobilephone',
                    'referenceMobileNumber',
                    'editingMobile'
                  )
                "
                >{{ $t("addresses.card.resetField") }}</v-btn
              >
            </div>
          </v-col>
        </v-row>

        <!-- email -->
        <v-row class="justify-space-between mb-2">
          <v-col cols="6" class="pa-0 pl-2 pr-1">
            <v-text-field
              ref="email"
              v-model="address.referenceEMail"
              :error-messages="errors"
              height="32"
              :placeholder="$t('addresses.card.email')"
              :rules="emailRules"
              :outlined="editingEmail"
              dense
              :clearable="editingEmail"
              :readonly="!editingEmail"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pa-0 pr-1 d-flex justify-center">
            <span
              v-if="!editingEmail"
              :class="address.referenceEMail ? 'edit' : 'insert'"
              @click="editField('editingEmail')"
              >{{
                address.referenceEMail
                  ? $t("addresses.card.editField")
                  : $t("addresses.card.insertField")
              }}</span
            >
            <div v-else class="d-flex flex-row">
              <v-btn
                class="ml-0 mr-1 white--text"
                color="primary"
                elevation="0"
                dense
                :disabled="!isValidInput('email')"
                @click="confirmEdit(address.referenceEMail, 'editingEmail')"
                >{{ $t("addresses.card.confirmField") }}</v-btn
              >
              <v-btn
                class="ml-0"
                elevation="0"
                dense
                @click="resetField('email', 'referenceEMail', 'editingEmail')"
                >{{ $t("addresses.card.resetField") }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
        <v-btn
          v-if="
            address.addressTypeId === 102 &&
              address.zoneId > 0 &&
              industryHasDelivery
          "
          color="primary"
          class="mb-3"
          @click="sendAddressActivationRequest"
          :disabled="clicked"
        >
          {{
            clicked
              ? "Richiesta di Attivazione Inviata"
              : "Richiedi attivazione"
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<style scoped lang="scss">
.address-card-container {
  height: 100%;
  .address {
    height: 100%;
  }
}
.address {
  background-color: #f8f8f8;
  min-height: 315px;
  i {
    font-size: 13px !important;
  }

  .delivery-address-select {
    color: #868686;
    font-size: 10px;
  }

  .industry-hasnt-delivery.v-card__title {
    // padding-top: 52px;
  }

  .has-delivery {
    &.active {
      color: $secondary;
    }
    color: $secondary;

    font-weight: bold;
    font-size: 10px;
    text-align: right;
    text-transform: uppercase;
    &.disabled {
      color: var(--v-grey-lighten1);
      opacity: 0.8;
    }
  }

  .v-card__title,
  .v-card__subtitle {
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    text-transform: uppercase;
    word-break: break-word;
    color: #515151;
    .value {
      font-weight: 400;
    }
  }
  .v-card__text {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    text-transform: uppercase;
    color: #515151;
    min-height: 55px;
  }

  .fields {
    .row {
      width: 100%;
    }
    .v-text-field {
      font-size: 12px;
      input:read-only {
        background-color: yellow;
      }
    }

    span {
      text-decoration: underline;
      font-size: 12px;
      font-weight: 700;
      display: inline-block;
      width: 100%;
      text-align: center;
      line-height: 38px;
      &.edit {
        color: $primary;
      }
      &.insert {
        color: red;
      }
    }

    // .v-btn {
    //   height: 40px;
    //   background: $white;
    //   padding: 0 7px;
    //   border: 1px solid $primary;
    //   color: $primary;
    //   border-radius: $border-radius-root;
    //   font-size: 10px;
    //   font-weight: 700;
    //   @media #{map-get($display-breakpoints, 'xs-only')} {
    //     height: 38px;
    //     font-size: 10px;
    //     min-width: 60px;
    //     max-width: 60px;
    //   }
    // }
  }
}
</style>

<script>
import SogegrossDeliveryService from "@/service/grosmarketCustomService";
import { mapActions, mapGetters, mapState } from "vuex";
import {
  requiredValue,
  isEmail,
  isMobilePhone,
  isHomePhone
} from "~/validator/validationRules";
export default {
  name: "AddressCard",
  props: ["address", "type", "selectionEnabled", "user"],
  data() {
    return {
      errors: [],
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      isHomePhoneRules: [isHomePhone()],
      isMobilePhoneRules: [isMobilePhone()],
      editingPhone: false,
      editingMobile: false,
      editingEmail: false,
      clicked: false
    };
  },
  mounted() {
    // necessario forzare l'initialValue all'inizio e per i reset successivi al primo update
    this.checkAlreadySentRequest();
    this.setInitialValues();
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      shippingAddressId: "cart/getAddressId",
      getUser: "cart/getUser"
    }),
    industryHasDelivery() {
      // check if user.person.industryName starts with CP o IP can't activate address
      if (this.getUser.person.industryName?.length >= 2) {
        let industryStartName = this.getUser.person.industryName
          .slice(0, 2)
          .toUpperCase();
        return industryStartName == "CP" || industryStartName == "IP"
          ? false
          : true;
      }
      return false;
    }
  },
  methods: {
    ...mapActions({ setAddress: "cart/setAddress" }),
    async sendAddressActivationRequest() {
      if (this.industryHasDelivery) {
        let res = await this.$dialog.confirm({
          text:
            "Vuoi richiedere che l'indirizzo venga attivato per la consegna?"
        });
        if (res) {
          SogegrossDeliveryService.requestAddressActivation(this.address);
          this.clicked = true;
        }
      }
    },
    async checkAlreadySentRequest() {
      let vm = this;
      var data = await SogegrossDeliveryService.checkAddressActivationAlreadySent(
        vm.address
      );
      vm.clicked = data.value;
    },
    isValidInput(field) {
      return this.$refs[field].valid;
    },
    resetField(field, model, flag) {
      this.address[model] = this.$refs[field].initialValue;
      this[flag] = false;
    },
    setInitialValues() {
      this.$refs.homephone.initialValue = this.address.referencePhoneNumber;
      this.$refs.mobilephone.initialValue = this.address.referenceMobileNumber;
      this.$refs.email.initialValue = this.address.referenceEMail;
    },
    editField(fieldToEdit) {
      this[fieldToEdit] = true;
    },
    confirmEdit(value, fieldToEdit) {
      this[fieldToEdit] = false;
      this.$emit("updateaddress", this.address);
    },
    /**
     * imposto il valore selected all'indirizzo prima di aggiornarlo
     */
    async setDeliveryAddress(address) {
      // let selectedAddress = address;
      // selectedAddress.selected = 1;
      // this.updateAddress(selectedAddress);
      let res = await this.setAddress({
        addressType: address.addressType,
        addressId: address.addressId
      });
      if (res && !res.errors) {
        this.$emit("refresh");
      } else {
        if (res.errors) {
          global.EventBus.$emit("error", {
            error: res.errors[0].error,
            message: res.errors[0].error
          });
        }
      }
    },
    /**
     * aggiorno l'indirizzo e poi effettuo reload di tutti gli indirizzi
     */
    async updateAddress(address) {
      try {
        this.error = null;
        this.loading = true;
        console.log(address);
        let res = await SogegrossDeliveryService.updateAddress(address);
        console.log(res);
        this.loading = false;
        if (res && !res.errors) {
          this.$emit("refresh");
        } else {
          if (res.errors) {
            global.EventBus.$emit("error", {
              error: res.errors[0].error,
              message: res.errors[0].error
            });
          }
        }
      } catch (response) {
        this.response = response;
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    address(val) {
      this.address = val;
      this.setInitialValues();
    }
  }
};
</script>
